<template>
  <div class="container">
    <h2>Oh nein!</h2>
    <p>Sie haben versucht eine Seite aufzurufen, die es gar nicht gibt.</p>
    <p>Bitte laden Sie die Seite neu, oder wechseln Sie auf die Hauptseite über das Menü oben.</p>
  </div>
</template>

<script>
  export default {
    name: 'Error'
  }
</script>

<style scoped lang="scss">
  @import '../style/bootstrap-component-include';
</style>
